<template>
    <div class="cd-body bg-white p-4">
        <!-- <surveyForm /> -->
        <div class="py-2 flex items-center justify-between">
            <h3 class="mr-4 whitespace-no-wrap">通話紀錄</h3>
            <div class="flex">
                <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="mr-4 w-64 input-rounded-full" v-model="query" />
                <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>
                    <vs-icon color="primary" icon="tune" size="small"></vs-icon>
                    <vs-dropdown-menu>
                        <div class="w-64">
                            <!-- 日期 -->
                            <div class="p-2">
                                <!-- 日期篩選捷徑 -->
                                <div class="flex cd-form-group items-center whitespace-no-wrap">
                                    <feather-icon class="mr-1 text-success" icon="CalendarIcon" svgClasses="h-4 w-5"></feather-icon>
                                    <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput('none')">{{ $t('apptHistory.all') }}</span>
                                    <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput('week')">{{ $t('apptHistory.week') }}</span>
                                    <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput('month')">{{ $t('apptHistory.month') }}</span>
                                    <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput(7)">{{ $t('apptHistory.sevenDays') }}</span>
                                    <span class="mr-1 text-success cd-p-pointer" @click="enterDateInput(30)">{{ $t('apptHistory.thirtyDays') }}</span>
                                </div>
                                <div class="flex cd-form-group items-center">
                                    <span class="mr-1 whitespace-no-wrap">{{ $t('apptHistory.startDate') }}</span>
                                    <el-date-picker
                                        class="w-full"
                                        popper-class="cd-appt-popper"
                                        v-model="startDate"
                                        type="date"
                                        :placeholder="$t('apptHistory.startDate')"
                                        value-format="yyyy-MM-dd"
                                        clearable
                                        @change="fetchCallLog">
                                    </el-date-picker>
                                </div>
                                <div class="flex items-center">
                                    <span class="mr-1 whitespace-no-wrap">{{ $t('apptHistory.endDate') }}</span>
                                    <el-date-picker
                                        class="w-full"
                                        popper-class="cd-appt-popper"
                                        v-model="endDate"
                                        type="date"
                                        :placeholder="$t('apptHistory.endDate')"
                                        value-format="yyyy-MM-dd"
                                        clearable
                                        @change="fetchCallLog">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>
        </div>
        <vs-divider />
        <div class="cd-form-group" v-for="(item, index) in data" :key="index">
            <div class="flex items-center">
                <feather-icon class="mx-1 text-success" icon="PhoneIncomingIcon" svgClasses="w-7 h-7" v-if="item.to_mid == dmid"></feather-icon>
                <feather-icon class="mx-1 text-success" icon="PhoneOutgoingIcon" svgClasses="w-7 h-7" v-else-if="item.from_mid == dmid"></feather-icon>
                <div class="ml-2">
                    <div class="flex items-end">
                        <span class="mr-2"># {{ item.call_id }}</span>
                        <div v-if="item.to_mid == dmid">
                            <span class="mr-2 text-lg">{{ item.from_name }}</span>
                            <span>{{ lang == 'en' ? item.from_title_en : item.from_title_cn }}</span>
                        </div>
                        <div v-if="item.from_mid == dmid">
                            <span class="mr-2 text-lg">{{ item.to_name }}</span>
                            <span>{{ lang == 'en' ? item.to_title_en : item.to_title_cn }}</span>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <feather-icon class="mr-1" icon="ClockIcon" svgClasses="w-3 h-3"></feather-icon>
                        <span>{{ getTimeAgo(item.created_at) }}</span>
                    </div>
                </div>
            </div>
            <vs-divider />
        </div>
        <div v-if="data.length > 0">
            <vs-pagination :max="windowWidth <= 600 ? 5 : 7" :total="Math.ceil(total/limit)" v-model="page" @change="fetchCallLog"></vs-pagination>
        </div>
        <div v-if="data.length == 0">
            沒有東東
        </div>
        <!-- <pre>{{ data }}</pre> -->
    </div>
</template>
<script>
import { fetchCallLog } from '@/api/user'
import { debounce } from 'lodash'
// import surveyForm from '@/components/surveyForm'
export default {
    // components: {
    //     surveyForm
    // },
    data() {
        return {
            data: [],
            query: '',
            limit: 20,
            page: 1,
            total: 0,
            startDate: null,
            endDate: null,
        }
    },
    mounted() {
        this.fetchCallLog()
    },
    watch: {
        query(){
            this.page = 1
            this.fetchCallLog()
        }
    },
    computed: {
        partnerid() {
            return this.$store.getters.partner
        },
        dmid() {
            return this.$store.state.mid
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        lang() {
            return this.$i18n.locale
        },
    },
    methods: {
        // 取通話紀錄
        fetchCallLog: debounce(function(event) {
            var payload = {
                "partner_id": this.partnerid,
                "page": this.page,
                "limit": this.limit
            }
            if(this.query)     payload.query = this.query
            if(this.startDate) payload.startDate = this.startDate
            if(this.endDate)   payload.endDate = this.endDate
            fetchCallLog(payload).then((res) => {
                if(res.data.status) {
                    this.data = res.data.data.data
                    this.total = res.data.data.total
                }
            })
        }, 200),
        // 取時間多久前
        getTimeAgo(timestamp){
            const now = Date.now();
            const then = Date.parse(timestamp.replace(/-/g, "/"));
            const seconds = Math.floor((now - then) / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
            const months = Math.floor(days / 30);
            const years = Math.floor(months / 12);
            
            if (seconds < 60) {
                return `${seconds}秒前`;
            } else if (minutes < 60) {
                return `${minutes}分鐘前`;
            } else if (hours < 24) {
                return `${hours}小時前`;
            } else if (days < 30) {
                return `${days}天前`;
            } else if (months < 12) {
                return `${months}個月前`;
            } else {
                return `${years}年前`;
            }
        },
        // 填入篩選日期
        enterDateInput(index){
            const today = new Date();
            if(index=="none"){
                this.endDate = this.startDate = null
            }else if(index=="week"){
                this.endDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
                today.setDate(today.getDate()-today.getDay()+1)
                this.startDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
            }else if(index=="month"){
                this.startDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-01'
                this.endDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
            }else{
                this.endDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
                today.setDate(today.getDate()-index+1)
                this.startDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
            }
            if(this.startDate) this.startDate = this.$moment(this.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
            if(this.endDate) this.endDate = this.$moment(this.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
            this.page = 1
            this.fetchCallLog()
        },
    }
}
</script>